import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

const Avatar = ({ image, name, department, linkedin, whatsapp, whatsappPrefix, mail, phone, phonePrefix, vertical, lg }) => {
  // Si le pasamos prefijo, se lo ponemos
  if (whatsappPrefix) {
    whatsapp = whatsappPrefix.toString() + whatsapp.toString()
  }
  // Según la documentación de WhatsApp hay que quitar los 0 o los +
  whatsapp = whatsapp.replace("+", "").replace('00', '')

  // Si le pasamos el prefijo, lo ponemos
  if (phonePrefix){
    phone = phonePrefix.toString() + phone.toString()
  }

  let avatarClassName = 'avatar'
  if (vertical) {
    avatarClassName += ' avatar--vertical'
  }
  if (lg) {
    avatarClassName += ' avatar--lg'
  }

  return (
    <div className={avatarClassName}>
      {/* Avatar */}
      {image !== undefined && (
        <div className="avatar__photo">
          <GatsbyImage
            alt={image.alternativeText}
            image={getImage(image.localFile)}
            placeholder="blurred"
            quality={100}
          />
        </div>
      )
      }
      {/* Job */}
      <div className="avatar__job">
        <b>{name}</b>
        {department !== undefined &&
          <span>{department}</span>
        }
      </div>
      {/* Social media */}
      {vertical && (
        linkedin !== undefined ||
        mail !== undefined ||
        whatsapp !== undefined ||
        phone !== undefined
      ) && (
          <div className="avatar__social-media">
            {linkedin !== undefined && (
                <div className="avatar__social-media__item">
                    <a href={linkedin} target="_blank">
                        <button className="button-icon button-icon--sm">
                            <i class="icon-linkedin-filled"></i>
                        </button>
                    </a>
                </div>
            )}
            {mail !== undefined && (
                <div className="avatar__social-media__item">
                    <a href={`mailto: ${mail}`} target="_blank">
                        <button className="button-icon button-icon--sm">
                            <i class="icon-mail"></i>
                        </button>
                    </a>
                </div>
            )}
            {whatsapp !== undefined && (
                <div className="avatar__social-media__item">
                    <a href={`https://wa.me/${whatsapp}`} target="_blank">
                        <button className="button-icon button-icon--sm">
                            <i class="icon-whatsapp"></i>
                        </button>
                    </a>
                </div>
            )}
            {phone !== undefined && (
                <div className="avatar__social-media__item">
                    <a href={`tel: ${phone}`} target="_blank">
                        <button className="button-icon button-icon--sm">
                            <i class="icon-phone"></i>
                        </button>
                    </a>
                </div>
            )}
          </div>
        )}
    </div>
  )
}

export default Avatar
